<template>
  <div>
    <v-alert v-if="msg" :type="msgType">{{ msg }}</v-alert>
    <ListPage ref="appTable" :endpoint="`${apiCall}?sort=-_id`" :headers="headers" title="Orders"
      :showActionsColumn="true" :showAddButton="isAllowed('createOrder')" :showEditButton="isAllowed('editOrder')"
      :showDeleteButton="isAllowed('deleteOrder')" :showViewButton="isAllowed('viewOrder')"
      @add="navigateTo('/app/orders/0')" @view="navigateTo(`/app/orders/view/${$event._id}`)">

      <template v-slot:item="{ item }" v-if="isMobile">
        <order-card :order="item" :icons="icons" @delete="removeItem"></order-card>
        <v-divider></v-divider>
      </template>
      <template v-slot:pStatus="{ item }" v-if="$vuetify.breakpoint.smAndUp">
        <label :class="`${['CREDIT', 'PAID', 'PARTIAL'].indexOf(item.pStatus) > -1 ? item.pStatus : 'CREDIT'}`">
          {{ item.pStatus }}
        </label>
      </template>

      <template v-slot:currentStatus="{ item }" v-if="$vuetify.breakpoint.smAndUp">
        <order-status :status="item.currentStatus"></order-status>
      </template>

      <template v-slot:date="{ item }">
        {{ item.date | dateFormat }}
      </template>

      <template v-slot:total="{ item }">
        {{ item.total | amountFormat }}
      </template>

      <template v-slot:delivery="{ item }">
        {{ item.delivery.date }}
      </template>
      <template v-slot:action="{ item }">
        <v-btn @click="navigateTo(`/app/orders/${item._id}`)" icon
          v-if="isAllowed('editOrder') && item.currentStatus !== 'DELIVERED'"><v-icon>mdi-pencil</v-icon></v-btn>
        <v-btn @click="deleteOrder(item._id)" icon v-if="isAllowed('deleteOrder')"><v-icon>mdi-delete</v-icon></v-btn>
      </template>
    </ListPage>
    <v-alert v-if="msg" :type="msgType">{{ msg }}</v-alert>
  </div>
</template>

<script>
import ActionButton from '@/components/ActionButton';
import ListPage from '@/components/common/ListPage.vue';
import OrderCard from '@/components/OrderResponsiveCard';
import appConstants from '@/utils/appConstants';

export default {
  components: {
    OrderCard,
    ActionButton,
    ListPage
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'Order Number', align: 'start', value: 'orderNo' },
        { text: 'Order Date', align: 'start', value: 'date' },
        { text: 'Customer', value: 'customer.displayName' },
        { text: 'Amount', value: 'total', align: 'right' },
        { text: 'Items', value: 'items.length', align: 'right' },
        { text: 'Payment Status', value: 'pStatus', align: 'right' },
        { text: 'Delivery Status', value: 'currentStatus', align: 'right' },
        { text: 'Delivery Date', value: 'delivery', align: 'right' },
        { text: 'Action', value: 'action', align: 'right' }
      ],
      icons: [
        { name: 'mdi-pencil', path: '/app/orders/', permission: 'editOrder' },
        { name: 'mdi-delete', permission: 'deleteOrder' }
      ],
      apiCall: appConstants.ORDERS_API,
      msg: '',
      msgType: ''
    };
  },
  methods: {
    async deleteOrder(id) {
      try {
        await this.deleteItem(`Are you sure you want to delete this order?`, `${appConstants.ORDERS_API}/${id}`);
        this.items?.splice(this.items.findIndex(rec => rec._id === id), 1);
        this.$refs.appTable.loadData(); // Refresh the ListPage data
      } catch (error) {
        this.handleError(error);
        this.msg = error.response.data.details;
      }
    },
    removeItem(id) {
      this.deleteOrder(id);
    },
    isAllowed(permission) {
      // Implement your permission logic here
      return true;
    },
    navigateTo(path) {
      this.$router.push(path);
    }
  }
};
</script>

<style scoped>
.PAID {
  color: green;
}

.PARTIAL {
  color: orange;
}

.CREDIT {
  color: red;
}
</style>